/**
 * Copyright (C) Astramint Software Solutions (P) Ltd - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 **/

(function($) {
    $.fn.dataTableDropDownFilter = function (options) {
        var self = this;

        var settings = $.extend({}, options);

        $table = $(settings.tableId).dataTable();

        $('.menu a', self).each(function () {
            $(this).click(function () {
                $table.fnFilter($(this).attr('data-filter-value'), $(self).attr('data-filter-column-index'));
                $('span', self).text($(this).text());
            });
        });

        return this;
    };

    $.fn.dataTableDropDownFilterSetTitle = function (value) {
        var self = this;

        $('.menu a', self).each(function () {
            if( $(this).attr('data-filter-value') == value)
                $('span', self).text($(this).text());
        });

        return this;
    }

    $.fn.dataTableSearchFilter = function (options) {
        var self = this;

        var settings = $.extend ({}, options);

        $table = $(settings.tableId).dataTable();

        $('button', self).click(function () {
            $table.fnFilter( $('input', self).val(), $(self).attr('data-filter-column-index'));
        });

        $('input', self).keypress(function (e) {
            var key = e.which;
            if(key == 13) {
                $table.fnFilter( $('input', self).val(), $(self).attr('data-filter-column-index'));
            }
        });

        return this;
    };

    $.fn.dataTableSearchFilterSetText = function (value) {
        var self = this;

        $('input', self).val(value);

        return this;
    };

    $.fn.dataTableDeleteConfirmShow = function (options) {
        var self = this;

        var settings = $.extend ({}, options);

        $('form', self).attr('action', settings.routeUrl);

       $(self).modal('show');

        return this;
    };

} (jQuery));