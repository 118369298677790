/* jshint devel:true */
/* jshint strict: true */
$(document).ready(function(){
    'use strict';
    /* Enable the side menu */
    $('#mainMenu').sidebar('attach events', '#mainMenuButton').sidebar('setting' , { transition: 'push'});

    /* Make all drop down as ui semantic drop down */
    $('.dropdown').dropdown();

    /* Make all checkbox as ui semantic checkbox */
    $('.checkbox').checkbox();

    /* Make all #tab .menu .item as tab */
    $('#tab.menu .item').tab();
});

function confirmDelete(button) {

    var $button = $(button);
    var modalId = '#' + $button.data('modal-id');
    var routeUrl = $button.data('href');

    $(modalId).dataTableDeleteConfirmShow({'routeUrl': routeUrl });
}